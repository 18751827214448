<template>
    <div class="sports_bet_list">

        <el-table
                :data="betList"
                style="width: 100%"
                border>
            <!--더보기-->
            <el-table-column type="expand" label="더보기" width="60">
                <template slot-scope="props">
                    <el-form label-position="left" class="demo-table-expand">
                        <el-form-item>
                            게임번호{{props.row.gameId}}, 경기시작시간:{{props.row.leisureGame.startTime}} <span v-if="props.row.leisureGame.autoclosed == managerConst.NOT" style="color: red">(게임이 수동으로 처리되였습니다)</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅번호"
                    width="80">
                <template slot-scope="scope">
                    <span :class="{'bg-danger':scope.row.leisureGame.autoclosed == managerConst.NOT}">{{scope.row.id}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="회원"
                    width="120">
                <template slot-scope="scope">
                    <span class="badge badge-success"  @click="editUser(scope.row.userId,managerConst.MODE_EDIT)">{{scope.row.user.nickname}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="경기유형"
                    width="120">
                <template slot-scope="scope">
                    {{scope.row.kindConfig.kindName}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="회차"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.leisureGame.sequence}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="선택값"
                    width="200">
                <template slot-scope="scope">
                    <span class="text-blue" style="font-weight: bold">({{scope.row.selectedValueText}})</span>
                    <span>{{scope.row.attributeConfig.attrName}}</span>
                    <span v-if="scope.row.leisureGame.homeTeamName != null">[{{scope.row.leisureGame.homeTeamName}} vs {{scope.row.leisureGame.awayTeamName}}]</span>
                    <span v-if="scope.row.leisureGame.homeTeamScore >=0 && scope.row.leisureGame.awayTeamScore>=0">[{{scope.row.leisureGame.homeTeamScore}}:{{scope.row.leisureGame.awayTeamScore}}]</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅시간"
                    width="110">
                <template slot-scope="scope">
                    {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅금액"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.betCash|comma}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="배당"
                    width="50">
                <template slot-scope="scope">
                    {{scope.row.betOdds}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="예상당첨"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.totalCash|comma}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅결과"
                    width="70">
                <template slot-scope="scope">
                    <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                    <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_WIN"
                          class="badge badge-primary">당첨</span>
                    <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_LOSE"
                          class="badge badge-danger">낙첨</span>
                    <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_CANCEL" class="badge badge-danger">취소</span>
                    <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_SPECIALCASE"
                          class="badge badge-danger">적특</span>
                    <span>
                         <br>{{scope.row.operator}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="정산여부"
                    width="70">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == managerConst.SportsBet.STATUS_COMPLITE">정산완료</span>
                    <span v-if="scope.row.status == managerConst.SportsBet.STATUS_WAITING">정산대기중</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="ACTION"
                    width="200">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini"
                               v-if="scope.row.ishighamount == managerConst.YES"
                               @click="updateHighAmountBet(scope.row.id, managerConst.NOT)">
                        확인(고액)
                    </el-button>
                    <el-button  type="warning" size="mini"
                                v-if="scope.row.alarm == managerConst.UNCHECKED"
                                @click="updateAlarm(scope.row.id, managerConst.CHECKED)">
                        확인(특)
                    </el-button>

                    <el-button  type="danger" size="mini"
                                v-if="scope.row.betResult != managerConst.SportsBet.BET_RESULT_CANCEL"
                                @click="cancelBet(scope.row.id)">취소
                    </el-button>
                    <el-button  type="danger" size="mini"
                                v-if="scope.row.display == managerConst.YES"
                                @click="updateDisplay(scope.row.id, managerConst.NOT)">
                        삭제
                    </el-button>

                    <el-button  type="danger" size="mini"
                                v-if="scope.row.display == managerConst.NOT"
                                @click="updateDisplay(scope.row.id, managerConst.YES)">
                        삭제복구
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import Vue from "vue";
    import UserEditToast from "./UserPanel/UserEditToast";
    import {updateLeisureGame, updateLeisureGameResultCancel} from "../../network/manager/leisureBetRequest";
    import {initSportsBetInfo} from "../../network/manager/sportsBetRequest";

    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "LeisureGameBetComp",
        data() {
            return {
                managerConst: managerConst,
                betList: [],
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },

        },
        methods: {
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            updateDisplay(id, s) {
                let update ={}
                update.id=id
                update.display=s
                updateLeisureGame(update).then(res=>{
                    this.$emit('leisureRefresh')
                })
            },
            updateAlarm(id, s) {
                let update ={}
                update.id=id
                update.alarm=s
                updateLeisureGame(update).then(res=>{
                    this.$emit('leisureRefresh')
                })
            },
            updateHighAmountBet(id, s) {
                let update ={}
                update.id=id
                update.ishighamount=s
                updateLeisureGame(update).then(res=>{
                    this.$emit('leisureRefresh')
                })
            },
            cancelBet(id) {
                this.$confirm('해당 베팅내역을  취소 하시겠습니까?', '베팅내역 취소', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let update = {}
                    update.id = id;
                    updateLeisureGameResultCancel(update).then(res => {
                        if (res.data.success) {
                            this.$emit('leisureRefresh')
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });

            },
            initBetInfo(id) {

            },
        },
        watch: {
            list: {
                handler(newVal) {
                    this.betList = newVal
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>
    .sports_bet_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table_sports_bet {
        width: 100%;
        margin-bottom: 10px;
    }

    .table_bet_games {
        width: 100%;
    }

    .table_bet_games td {
        line-height: 40px !important;
        border-bottom: 1px solid #cfcfd1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px;
    }
    .bg-danger{
        background-color: #f1ae17;
    }
</style>