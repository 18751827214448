<template>
    <div>
        <div class="search">
            <div class="date_selector">
                <div class="block">
                    <span class="demonstration"></span>
                    <el-date-picker size="mini"
                                    style="width: 130px"
                                    v-model="startDate"
                                    align="right"
                                    type="date"
                                    placeholder="시작일"
                                    :picker-options="pickerOptions"
                                    @change="setStartDate">
                    </el-date-picker>
                </div>
                <div style="padding-left: 1px;padding-right: 1px;color: #cfcfd1">~</div>
                <div class="block">
                    <span class="demonstration"></span>
                    <el-date-picker size="mini"
                                    style="width: 130px"
                                    v-model="endDate"
                                    align="right"
                                    type="date"
                                    placeholder="마감일"
                                    :picker-options="pickerOptions"
                                    @change="setEndDate">
                    </el-date-picker>
                </div>
            </div>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
            </el-button>
            <el-select v-model="honorCasinoBet.betResult" @change="search" size="mini" placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="13" label="전체" :value="null">전체</el-option>
                <el-option key="15" label="당첨" :value="managerConst.SportsBet.BET_RESULT_WIN">당첨</el-option>
                <el-option key="16" label="낙첨" :value="managerConst.SportsBet.BET_RESULT_LOSE">낙첨</el-option>
            </el-select>
        </div>
        <div style="padding: 5px 0;font-size: 14px">
            <span style="padding-left: 20px;">총건수:{{totalBetCount|comma}}건</span>
            <span style="padding-left: 20px;">
                        총베팅금액:{{totalBetCash|comma}}원
                    </span>
            <span style="padding-left: 20px;">당첨금액:{{totalBetWinCash|comma}} 원 </span>
            <span style="padding-left: 20px;">손이익:{{(totalBetWinCash - totalBetCash)|comma}} 원 </span>
        </div>
        <div>
          <el-table
              :data="honorCasinoBetList"
              style="width: 100%"
              max-height="1100"
              border>
            <el-table-column
                label="Vendor"
                width="200">
              <template slot-scope="scope">
                {{scope.row.vendor}}
              </template>
            </el-table-column>
            <el-table-column
                label="Title"
                width="500">
              <template slot-scope="scope">
                {{scope.row.title}}
              </template>
            </el-table-column>
            <el-table-column
                label="베팅금액"
                width="140">
              <template slot-scope="scope">
                {{scope.row.betamount|comma}}
              </template>
            </el-table-column>
            <el-table-column
                label="당첨금액"
                width="140">
              <template slot-scope="scope">
                {{scope.row.winamount|comma}}
              </template>
            </el-table-column>
            <el-table-column
                label="베팅결과"
                width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_WIN" class="badge badge-primary">당첨</span>
              </template>
            </el-table-column>

            <el-table-column
                label="날짜"
                width="130">
              <template slot-scope="scope">
                {{scope.row.betTimeKr|datef('MM-DD HH:mm')}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[15, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>
    import managerConst from "../../common/administrator/managerConst";

    import DateSelectorComp from "./DateSelectorComp";
    import {getLeisureGameBetStatisticByAttribute, getLeisureGameList} from "../../network/manager/leisureBetRequest";
    import {getHonorCasinoBetlist} from "../../network/manager/honorCasinoRequest";


    export default {
        name: "UserInfoHonorCasinoBetComp",
        components: {DateSelectorComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                honorCasinoBet: {partnerId: null, user: {}},
                honorCasinoBetList: [],
                pageNum: 1,
                pageSize: 15,
                pageTotal: 0,
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetWinCash: 0,
                managerConst: managerConst,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        props: {
            uid: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            search() {
                this.getHonorBetList();
            },
            getHonorBetList() {
                this.honorCasinoBet.startDate = this.startDate
                this.honorCasinoBet.endDate = this.endDate
                getHonorCasinoBetlist(this.honorCasinoBet, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.data.pageTotal;
                    this.honorCasinoBetList = res.data.data.list;
                    this.totalBetCount = res.data.data.pageTotal;
                    this.totalBetCash = res.data.data.statistic.betamount;
                    this.totalBetWinCash = res.data.data.statistic.winamount;
                })

            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.search();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.leisureGameBet.partnerId = parterId
                this.search();
            },
            leisureRefresh() {
                this.search();
            }
        },
        created() {

        },
        watch: {
            uid: {
                handler(newVal) {
                    if (newVal != null && newVal != 0) {
                        this.startDate = this.$moment().format('yyyy-MM-DD')
                        this.endDate = this.$moment().format('yyyy-MM-DD')
                        this.honorCasinoBet.userId = this.uid
                        this.search()
                    }
                },
                immediate: true,
                deep: false
            },
        }
    }
</script>

<style scoped>

</style>